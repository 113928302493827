import React from 'react';

const PlayerItem = ({handleFormChange, instance, form, playerNum, memberList}) => {
    return (
        <select name={"Player " + (playerNum + 1)} data-key={"p" + (playerNum + 1)}
        value={form["p" + (playerNum + 1)]} onChange={handleFormChange}
        data-instance={instance}>
            <option value="" defaultValue>Choose Names...</option>
            { 
                memberList.map((indMember) => (
                    <option key={indMember.name} value={indMember._id}>{indMember.name}</option>
                ))
            }
        </select>
    )
}

export default PlayerItem;