import React from 'react';
import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './General/Header.js';
import Footer from './General/Footer.js';
import ForgotPassword from './General/ForgotPassword.js';
import PasswordReset from './General/PasswordReset.js';
import Home from './General/Home.js';
import Login from './General/Login.js';
// import Signup from './General/Signup.js';
import Infractions from './Infractions/Infractions.js';
import CreateCV from './Infractions/CreateCV.js';
import UpdateCV from './Infractions/UpdateCV.js';
import Members from './Members/Members.js';
import CreateMember from './Members/CreateMember.js';
import UpdateMember from './Members/UpdateMember.js';
import PurchaseOrders from './PurchaseOrders/PurchaseOrders.js';
import CreatePO from './PurchaseOrders/CreatePO.js';
import UpdatePO from './PurchaseOrders/UpdatePO.js';
import Programs from './Programs/Programs.js';
import CreatePrograms from './Programs/CreatePrograms.js';
import UpdatePrograms from './Programs/UpdatePrograms.js';
import { UserContext } from './contexts/UserContext.js';
import { DataContext } from './contexts/DataContext.js';
import { onInitialUserCheck } from './functional/helperFunctions.js';

function App() {
  const [ userPrefs, setUserPrefs ] = useState({
    name: "",
    isLoggedOn: false,
    privilige: 0,
    department: ""
  })

  const updateUserPrefs = (value) => {
    setUserPrefs(value);
  }

  const [ loadedData, setLoadedData ] = useState(true);

  const updateLoadedData = (value) => {
    setLoadedData(value);
  }

  useEffect(() => {
    onInitialUserCheck(updateUserPrefs);
}, [])

  // <Route path="signup" element={<Signup />} />
  //

  return (
    <div className="App">
        <UserContext.Provider value={{userPrefs, updateUserPrefs}}>
        <DataContext.Provider value={{loadedData, updateLoadedData}}>
          <BrowserRouter>
              <Header />
              <Routes>
                {/* <Route path="signup" element={<Signup />} /> */}
                <Route path="login" element={<Login />} />
                <Route path="/" element={<Home />} />
                <Route path="/forgot-password/" element={<ForgotPassword />} />
                <Route path="/reset-password/:id/:token" element={<PasswordReset />} /> 
                <Route path="court-infractions/" element={<Infractions />} />
                <Route path="court-infractions/:id" element={<UpdateCV />} />
                <Route path="infraction-create" element={<CreateCV />} />
                <Route path="members/" element={<Members />} />
                <Route path="members/:id" element={<UpdateMember />} />
                <Route path="member-create/" element={<CreateMember />} />
                <Route path="purchase-orders/" element={<PurchaseOrders />} />
                <Route path="purchase-orders/:id" element={<UpdatePO />} />
                <Route path="po-create" element={<CreatePO />} />
                <Route path="programs/" element={<Programs />} />
                <Route path="programs/:id" element={<UpdatePrograms />} />
                <Route path="programs-create" element={<CreatePrograms />} />
              </Routes>
              <Footer />
          </BrowserRouter>
        </DataContext.Provider>
        </UserContext.Provider>
    </div>
  );
}

export default App;