import React from 'react';
import InfractionsTable from '../Infractions/InfractionsTable.js'
import PurchaseOrdersTable from '../PurchaseOrders/PurchaseOrdersTable.js'
import MembersTable from '../Members/MembersTable.js'
import ProgramsTable from '../Programs/ProgramsTable.js'

const TableSet = ({currentData, mode, extraFunc, extraFunc2}) => {
    if (mode === "infractions")
    {
        return (
            <InfractionsTable currentData={currentData} extraFunc={extraFunc} extraFunc2={extraFunc2} />
        )
    }
    else if (mode === "porders")
    {

        return(
            <PurchaseOrdersTable currentData={currentData} apiLink="/purchase-orders/" />
        )
    }
    else if (mode === "members")
    {
        return(
            <MembersTable currentData={currentData} apiLink="/members/" />
        )
    }
    else if (mode === "programs")
    {
        return(
            <ProgramsTable currentData={currentData} apiLink="/programs/" />
        )
    }
    else
    {
        return(
            <div>
                Contact website administrator...
            </div>
        )
    }



}

export default TableSet;