import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ProgramUpdateForm from '../Programs/ProgramUpdateForm.js';


const UpdateProgram = () => {
    const { id } = useParams();
    const heading = "Update a Program";

    const fetchSingleProgram = async (updateState) => {    
        const data = await fetch(process.env.REACT_APP_API + '/api/programs/'+ id, {
            credentials: 'include',
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        })
    
        const json = await data.json();
    
        updateState(json);
    }
    
    const [ form, setForm ] = useState({});
    useEffect(() => {
        fetchSingleProgram(setForm);
    }, [])

    return(
        <div>
            <div className="grid-container" id="create-form">
                <div className="general-header" id="home-top">
                    <h2 className="general-heading">{ heading }</h2>
                </div>            
                <ProgramUpdateForm form={form}/> 
            </div>
        </div>
    )

}

export default UpdateProgram;