import { useState } from 'react';
import loginImage from '../images/tennis.jpg';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    // Setup redirect
    const navigate = useNavigate();
    const routeLoginChange = () => {
        navigate('/login');
    }
    const routeSignupChange = () => {
        navigate('/signup');
    }

    const [ response, setResponse ] = useState({})
    const [ email, setEmail ] = useState()

    //Function to update currentItem based off changes in each individual form field
    const handleChange = (e, updateItem, currentItem) => {
        const name = e.target.getAttribute('data-key');
        const value = e.target.value;
        
        updateItem({...currentItem, [name]: value});
    };

    const postForgotPassword = async (e, email, updateResponse, history) => {
        e.preventDefault();
        updateResponse({});
        
        const data = await fetch(process.env.REACT_APP_API + '/api/forgot-password', {
            credentials: 'include',
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(email),
        })
        
        const json = await data.json();
        
        if (json.response)
        {
            // this will be the email error that it is not in the database
            updateResponse(response => ({...response, 
                        email: json.response.email, 
            }));
        }
        
        if (json.result)
        {
            // this will be 
            history("/");
        }
    }

    return (
        <div className="userauth-container" >
            <div className="login-container">
                <img src={ loginImage } className="login-image" alt="Hoi Pham on Unsplash"/>
                <form className="login-box">
                    <h3 className="login-heading">Forgot Password?</h3>
                    <input type="text" 
                        className="login-input" 
                        placeholder="Email" 
                        onChange={(e) => { handleChange(e, setEmail, email)} }
                        data-key="email" />
                    <div className="login-error">{ response.email }</div>
                    <input type="button" 
                        onClick={(e) => { postForgotPassword(e, email, setResponse, navigate)}} 
                        value="Submit" 
                        className="general-button login-button login-submit"/>
                    <div className="login-orbox">
                        <div className="login-line"></div>
                        <span className="login-ortext">OR</span>
                        <div className="login-line"></div>
                    </div>
                    <input type="button" 
                        onClick={ routeLoginChange } 
                        value="Login" 
                        className="general-button login-button login-forgot"/>
                    <input type="button" 
                        onClick={ routeSignupChange } 
                        value="Don't have an account?" 
                        className="general-button login-button login-submit"/>
                </form>
            </div>
        </div>
    )
}

export default ForgotPassword;