import React from 'react';
import { useState, useEffect } from 'react';

const POLineItem = ({ handleFieldUpdate, handleRemoveItem, instance, lineInstance, form }) => {
    const [ lineItems, setLineItems ] = useState({})

    const onRemoveLItem = () => {
        handleRemoveItem(lineInstance)
    }

    useEffect(() => {
        try {
            if (form.lineitems[lineInstance])
            {
                for (var i = 0; i < form.lineitems.length; i++)
                {
                    setLineItems(form.lineitems[lineInstance])
                }
            }
        }
        catch {
            console.log('waiting on re-render')
        }

    },[form])

    return (
        <tr>
            <td>
                <a href="#" id="po-removeitem"  onClick={ onRemoveLItem }
                    className="general-button">
                    x
                </a>
                <input type="text" 
                        className="poform-xsmall" 
                        placeholder="#"
                        onChange={ handleFieldUpdate }
                        data-key="quantity"
                        data-instance={ instance }
                        data-lineinstance = { lineInstance }
                        value={lineItems.quantity} />
            </td>
            <td>
                <input type="text" 
                        className="poform-xxlarge" 
                        placeholder="Fill in..."
                        onChange={ handleFieldUpdate }
                        data-key="description"
                        data-instance={ instance }
                        data-lineinstance = { lineInstance }
                        value={lineItems.description} />
            </td>
            <td>
                <input type="text" 
                        className="poform-xsmall" 
                        placeholder="$"
                        onChange={ handleFieldUpdate }
                        data-key="unit"
                        data-instance={ instance }
                        data-lineinstance = { lineInstance }
                        value={lineItems.unit} />
            </td>
            <td>
                <p className="poform-readonly" 
                   data-key="cost"
                   data-instance={ instance }
                   data-lineinstance = { lineInstance }>
                    {lineItems.cost} 
                </p>
            </td>                                        
        </tr>
    )
}

export default POLineItem;