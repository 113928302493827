import React from 'react';
import { useState, useEffect } from 'react';

const POFilter = ({ togglePopUp, addSearchParams, poRecords }) => {   
    // date, gl, payee, supplier
    const [ dateOptions, setDateOptions ] = useState()
    const [ glOptions, setGLOptions ] = useState()
    const [ payeeOptions, setPayeeOptions ] = useState()
    const [ supplierOptions, setSupplierOptions ] = useState()
    
    const handleToggle = () => {
        togglePopUp();
    }

    const onFieldUpdate = (e) => {
        addSearchParams(e.target.name, e.target.value)
        togglePopUp();
    }

    const recordProcessing = (poRecords) => {
        let dateTable = {}
        let glTable = {}
        let payeeTable = {}
        let supplierTable = {}

        poRecords.map((indRecord) => {
            if (!dateTable.hasOwnProperty(indRecord.date))
            {
                dateTable[indRecord.date] = true;
            }

            if (!glTable.hasOwnProperty(indRecord.gl))
            {
                glTable[indRecord.gl] = true;
            }

            if (!payeeTable.hasOwnProperty(indRecord.payee))
            {
                payeeTable[indRecord.pto] = true;
            }

            if (!supplierTable.hasOwnProperty(indRecord.supplier))
            {
                supplierTable[indRecord.sto] = true;
            }
        })


        selectionCreator(dateTable, setDateOptions)
        selectionCreator(glTable, setGLOptions)
        selectionCreator(payeeTable, setPayeeOptions)
        selectionCreator(supplierTable, setSupplierOptions)
    }

    const selectionCreator = (table, updateState) => {
        let selections = []

        let descSortedArray = Object.keys(table).sort()
        descSortedArray = descSortedArray.reverse()
        for (const keyValue of descSortedArray)
        {
            selections.push(
                <option key={keyValue} value={keyValue}>{keyValue}</option>
            )
        }

        updateState(selections.sort())
    }

    useEffect(() => {
        recordProcessing(poRecords);
    }, [togglePopUp])

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="modal-close" onClick={handleToggle}>
                    &times;
                </span>
                <h3>Filter:</h3>
                <form>
                    <select name="approved" data-key="approved"
                            value="approved" onChange={onFieldUpdate}>
                        <option value="" defaultValue>Check Status...</option>
                        <option key={0} value={true}>Approved</option>
                        <option key={1} value={false}>Not Approved</option>
                    </select>
                    <select name="date" data-key="date"
                            value="date" onChange={onFieldUpdate}>
                        <option value="" defaultValue>Search Date...</option>
                        {dateOptions}
                    </select>
                    <br />
                    <select name="gl" data-key="gl"
                            value="gl" onChange={onFieldUpdate}>
                        <option value="" defaultValue>Search GL# ...</option>
                        { glOptions}
                    </select>
                    <br />
                    <select name="pto" data-key="pto"
                            value="pto" onChange={onFieldUpdate}>
                        <option value="" defaultValue>Search Payee Name...</option>
                        { payeeOptions}
                    </select>
                    <br />
                    <select name="sto" data-key="sto"
                            value="sto" onChange={onFieldUpdate}>
                        <option value="" defaultValue>Search Supplier Name...</option>
                        { supplierOptions}
                    </select>
                    <br />
                </form>
            </div>
        </div>
    )
}

export default POFilter;