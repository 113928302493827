import React from 'react';
import { useState, useEffect } from 'react';
import SelectTimeField from '../functional/SelectTimeField.js';
import { fetchMemberNames } from '../functional/helperFunctions.js';


const CVFilter = ({ togglePopUp, addSearchParams }) => {   
    // useState declarations
    const [ memberSelections, setMemberSelections ] = useState([])
   
    // Toggles pop up on screen  
    const handleToggle = () => {
        togglePopUp();
    }

    // Adds search parameter to state from Infractions component for data filter
    const onFieldUpdate = (e) => {
        addSearchParams(e.target.name, e.target.value)
        togglePopUp();
    }

    // helper function query to check member names and populate filter selection box
    useEffect(() => {
        fetchMemberNames(setMemberSelections);
    }, [togglePopUp])

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="modal-close" onClick={handleToggle}>
                    &times;
                </span>
                <h3>Filter:</h3>
                <form>
                    <select name="infraction" onChange={onFieldUpdate}>
                        <option value="" defaultValue>Select Infraction...</option>
                        <option value="Late Cancel">Late Cancel</option>
                        <option value="No Show">No Show</option>
                        <option value="Singles To Doubles">Singles To Doubles</option>
                        <option value="Guest TBD">Guest TBD</option>
                        <option value="Guest Name">Guest Name</option>
                    </select>
                    <br />
                    <input type="date" name="date" onChange={onFieldUpdate} />
                    <br />
                    <SelectTimeField handleFormChange={onFieldUpdate} form={{}} instance={0} />
                    <br />
                    <select name="name" data-key="name"
                            value="name" onChange={onFieldUpdate}>
                        <option value="" defaultValue>Search Name...</option>
                        { memberSelections.map((indMember) => (
                            <option key={indMember.name} value={indMember._id}>{indMember.name}</option>
                        ))}
                    </select>
                </form>


            </div>
        </div>
    )
}

export default CVFilter;