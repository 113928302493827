import React from 'react';
import { Link } from 'react-router-dom'

const ProgramsTable = ({currentData, apiLink }) => {
    return(
        <table className="home-table" id="home-infractiontable">
                <tbody>
                    <tr>
                        <td className="home-tableheading">Title</td>
                        <td className="home-tableheading">Description</td>
                        <td className="home-tableheading">Date</td>
                        <td className="home-tableheading">Participants</td>
                        <td className="home-tableheading">Days to Register</td>
                        <td className="home-tableheading">Email</td>
                        <td className="home-tableheading">Author</td>
                    </tr>
                    { currentData.map((rec) => (
                        <tr key={rec._id}>
                            <td>{rec.title}</td> 
                            <td>{rec.description}</td> 
                            <td>{rec.date}</td>
                            <td>{rec.participants}</td>
                            <td>{rec.daysToRegister}</td>
                            <td>{rec.recipientEmail}</td>
                            <td>{rec.author }</td>
                            <td>
                                <Link to={ apiLink + rec._id } className="general-button home-updatebutton">Update</Link>
                            </td>
                        </tr>
                    ))}     
                </tbody>       
            </table>
    )
}

export default ProgramsTable;