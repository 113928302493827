import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MemberForm from './MemberForm.js';
import { handleItemDelete,
         handleCookieValidation, 
         handleFieldUpdate 
        }  from '../functional/helperFunctions.js';

const MemberUpdateForm = ({ form }) => { 
    const navigate = useNavigate();
    const routeChange = () => {
        navigate('/members');
    }
    const routeLoginChange = () => {
        navigate('/login');
    }

    // Updates the k,v pair of a form field for a given object
    const onFieldUpdate = (e) => {
        handleFieldUpdate(e, updateState);
    }

    const updateState = (key, value) => {
        setCurrentItem({...currentItem, [key]: value});
    }

    const onUpdateDelete = async () => {
        const redirectPage = await handleItemDelete(currentItem._id, setErrors, '/api/members/');

        if (redirectPage === 0)
        {
            routeChange();
        }
        else if (redirectPage === 1)
        {
            routeLoginChange();
        }
        else if (redirectPage === 2)
        {
            console.log('Privilige Error')
        }
        else
        {
            console.log('Unknown Update Delete Error')
        }
    }

    useEffect(() => {
        handleCookieValidation(routeLoginChange);
    },[])

    const [ errors, setErrors ] = useState({})
    const fetchPUTInfraction = async () =>{
        var errorFlag = false;

        const data = await fetch(process.env.REACT_APP_API + '/api/members/' + currentItem._id, {
            credentials: 'include',
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(currentItem),
        })

        const json = await data.json();

        if (json.errors)
        {
            errorFlag = true;
            setErrors({});

            if (json.errors.jwt)
            {
                routeLoginChange();
            }
            else if (json.errors.privilige)
            {
                setErrors({ "privilige": "Errors - Your account privilige is too low, contact Site Administrator" })
            }

        }

        if (!errorFlag)
        {
            routeChange();
        }
    }

    const onUpdateSubmit = () => {
        console.log('updating...')
        fetchPUTInfraction();
    }
    
    // Sets basic placeholder object before data loads from backend
    const [ currentItem, setCurrentItem ] = useState({
        _id: "", name:""
    })

    // When the form loads in from parent component, sets state
    useEffect(() => {
        setCurrentItem({
            _id: form._id,
            name: form.name ? form.name : "",
            email: form.email ? form.email : "",
        })
    },[form])

    return (
        <div className="form-container" id="form-update">
            <MemberForm handleFieldUpdate={onFieldUpdate} key={ 0 } instance={ 0 } form={currentItem} errors={errors} formType={'update'} />
            <div className="form-instance">
                <input type="button" 
                    onClick={ onUpdateSubmit } 
                    value="Update" 
                    className="general-button form-updatebutton"/>
                <input type="button" 
                    onClick={ onUpdateDelete } 
                    value="Delete" 
                    className="general-button form-deletebutton"/>
            </div>
        </div>
    )
}

export default MemberUpdateForm;