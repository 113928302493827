import React from 'react';
import { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import TableSet from './TableSet.js';

const DataBoxView = ({ dataSet, itemsPerPage, mode, extraFunc, extraFunc2 }) => {
    // Start with empty item list
    const [ currentItems, setCurrentItems ] = useState([]);
    const [ pageCount, setPageCount] = useState(0);
    const [ itemOffset, setItemOffset ] = useState(0);

    useEffect(() => {
        // Fetch items from another resource
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(dataSet.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(dataSet.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, dataSet]);

    // Change pages
    const handlePageClick = (e) => {
        const newOffset = (e.selected * itemsPerPage) % dataSet.length;
        setItemOffset(newOffset);
    }

    return (
        <div id="home-boxview">
            <TableSet currentData={currentItems} mode={mode} extraFunc={extraFunc} extraFunc2={extraFunc2} />
            <ReactPaginate
            breakLabel="..."
            nextLabel="&nbsp;>&nbsp;"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="&nbsp;<&nbsp;"
            renderOnZeroPageCount={null}
            containerClassName="paginate-container"
            pageLinkClassName="paginate-page"
            previousLinkClassName="paginate-previous"
            nextLinkClassName="paginate-next"
            />
        </div>
    )
}

export default DataBoxView;