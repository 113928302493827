export const handleItemDelete = async (idVal, updateState, api_url) => {
    console.log('deleting...')

    const data = await fetch(process.env.REACT_APP_API + api_url + idVal, {
        credentials: 'include',
        method: 'DELETE',
        headers: {'Content-Type': 'application/json'},
    })

    const json = await data.json();

    if (json.errors)
    {
        updateState({});
        console.log(json.errors)

        if (json.errors.privilige)
        {
            updateState({ "privilige": json.errors.privilige })
            return 2;
        }
        else if (json.errors.jwt)
        {
            return 1;
        }
    }
    else
    {
        return 0;
    }
}

export const handleCookieValidation = (redirectPath) => {
    // Fetch request to login use loginDetails state
    fetch(process.env.REACT_APP_API + '/api/validate', {
        method: 'GET',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
    })
    .then(res => {
        return res.json();
    })
    .then((data) => {
        if(data.message)
        {
            return data;
        }
        else if (data.error === 'jwt')
        {
            redirectPath();
            return data;
        }
        else 
        {
            console.log('troubleshoot');
            return data;
        }
    })
}

export const handleFieldUpdate = (e, updateState) => {
    const changeKey = e.target.getAttribute('data-key');
    const changeLineItemKey = e.target.getAttribute('data-lineinstance')
    const changeValue = e.target.value;

    if (changeLineItemKey)
    {
        updateState(changeKey, changeValue, changeLineItemKey);
    }
    else
    {
        updateState(changeKey, changeValue);
    }
}

export const onInitialUserCheck = (updateUserPrefs) => {
    // Fetch request to login use loginDetails state
    fetch(process.env.REACT_APP_API + '/api/validate', {
        method: 'GET',
        credentials: 'include',
        headers: {'Content-Type': 'application/json'},
    })
    .then(res => {
        return res.json();
    })
    .then((data) => {
      if(data.user)
      {
          updateUserPrefs({ name: data.user,
                            isLoggedOn: data.isLoggedOn, 
                            privilige: data.privilige,
                            department: data.department})
      }
      else if (data.error === 'jwt')
      {
          return
      }
      else 
      {
        return
      }
    })
  } 

export const fetchMemberNames = async (updateState) => {
    const data = await fetch(process.env.REACT_APP_API + '/api/members', {
        credentials: 'include',
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
    })

    const json = await data.json();

    updateState(json);
}

export const fetchAlphaNum = async (poNum, updateState) => {
    const data = await fetch(process.env.REACT_APP_API + '/api/alpha-num/' + poNum, {
        credentials: 'include',
        method: 'GET',
        headers: {'Content-Type': 'application/json'},
    })

    const json = await data.json();

    updateState(form => ({...form, "polet": json.result}));
}

export const updatePOState = (key, value, lineInstance, currentState, updateState) => {
    if (key === "quantity" || key === "description" ||
    key === "unit" || key === "cost")
    {
        if (key === "quantity" || key === "unit")
        {
            var currentObject = currentState
            currentObject.lineitems[lineInstance][key] = value;

            if (currentObject.lineitems[lineInstance].quantity &&
                currentObject.lineitems[lineInstance].unit)
            {
                const costValue = (parseFloat(currentObject.lineitems[lineInstance].quantity) * 
                                   parseFloat(currentObject.lineitems[lineInstance].unit))
                                   .toFixed(2).toString();

                currentObject.lineitems[lineInstance].cost = costValue;

                var subtotalValue = 0
                for (var i = 0; i < currentObject.lineitems.length; i++)
                {
                    subtotalValue += parseFloat(currentObject.lineitems[i].cost)
                }
                
                const hst = (subtotalValue * 0.13).toFixed(2).toString();
                currentObject.subtotal = subtotalValue.toFixed(2).toString();
                currentObject.hst = hst;
                currentObject.total = (parseFloat(subtotalValue) + parseFloat(hst)).toFixed(2).toString();
            }
            updateState({...currentState, currentObject})
        }
        else
        {
            var currentObject = currentState.lineitems[lineInstance]
            currentObject[key] = value
            updateState({...currentState, currentObject})
        }
    }
    else
    {
        if (key === "hst")
        {   
            var currentObject = currentState
            currentObject[key] = value

            const newTotal = parseFloat(currentObject.hst) + parseFloat(currentObject.subtotal);
            currentObject.total = newTotal;

            updateState({...currentState, currentObject})
        }
        else
        {
            updateState({...currentState, [key]: value});
        }
    }
}

export const getDateAndPONum = (dept) => {
    const today = new Date();
    const thisMonth = today.getMonth() + 1;
    const thisDay = today.getDate();

    var todayDate = "";
    var todayPONum = "";

    if (thisMonth < 10)
    {
        if (thisDay < 10)
        {
            todayDate = today.getFullYear() + '-0' + thisMonth + '-0' + thisDay;
            todayPONum = dept + today.getFullYear().toString().slice(-2) + '0' + thisMonth + '0' + thisDay;

            return [todayDate, todayPONum]
        }
        else
        {
            todayDate = today.getFullYear() + '-0' + thisMonth + '-' + thisDay;
            todayPONum = dept + today.getFullYear().toString().slice(-2) + '0' + thisMonth + thisDay;

            return [todayDate, todayPONum]
        }
    }
    else
    {
        if (thisDay < 10)
        {
            todayDate = today.getFullYear() + '-' + thisMonth + '-0' + thisDay;
            todayPONum = dept + today.getFullYear().toString().slice(-2) + thisMonth + '0' + thisDay;

            return [todayDate, todayPONum]
        }
        else
        {
            todayDate = today.getFullYear() + '-' + thisMonth + '-' + thisDay;
            todayPONum = dept + today.getFullYear().toString().slice(-2) + thisMonth + thisDay;

            return [todayDate, todayPONum]
        }
    }
}

export const handleInfractionHighlight = (infraction, updateState, buttonStyleObject) => {
    const selectedInfraction = "general-button form-selectedbutton ";
    const normalInfraction = "general-button form-updatebutton ";

    if (infraction.infraction)
    {
        var tempObject = buttonStyleObject;

        for (const [k, v] of Object.entries(buttonStyleObject)) {
            if (infraction.infraction === k)
            {       
                if (k === "Singles To Doubles")
                {
                    tempObject[k] = selectedInfraction + "large"
                }
                else
                {
                    tempObject[k] = selectedInfraction + "normal"
                }
            }
            else
            {
                if (k === "Singles To Doubles")
                {
                    tempObject[k] = normalInfraction + "large"
                }
                else
                {
                    tempObject[k] = normalInfraction + "normal"
                }                                            
            }
        }
        updateState(tempObject)
    }
}