import React from 'react';
import { Link } from 'react-router-dom'

const MembersTable = ({currentData, apiLink}) => {
    return (
        <table className="home-table" id="home-infractiontable">
            <tbody>
            <tr>
                <td className="home-tableheading">Name</td>
                <td className="home-tableheading">Update</td>
            </tr>
            { currentData.map((rec) => (
                <tr key={rec._id}>
                    <td>{rec.name}</td>
                    <td>{rec.email}</td>
                    <td>
                        <Link to={ apiLink + rec._id } className="general-button home-updatebutton">Update</Link>
                    </td>
                </tr>
            ))}     
            </tbody>       
        </table>
    )
}

export default MembersTable;