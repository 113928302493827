import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import TitleBar from '../General/TitleBar.js';
import DataBoxView from '../functional/DataBoxView.js';
import POFilter from '../PurchaseOrders/POFilter.js';
import { handleCookieValidation } from '../functional/helperFunctions.js';
import { DataContext } from '../contexts/DataContext.js';

const PurchaseOrders = () => {
    // Data loaded
    const { loadedData, updateLoadedData } = useContext(DataContext);

    // Setup redirect
    const navigate = useNavigate();
    const routeLoginChange = () => {
        navigate('/login');
    }

    const heading = "Purchase Order Records";
    const buttonName = "Filter..." 

    const [ pOrders, setPOrders ] = useState([]);
    const [ errors, setErrors ] = useState({})
    const [ seen, setSeen ] = useState(false);
    const togglePopUp = () => {
        setSeen(false);
    }

    const handleFilter = () => {
        setSeen(true);
    }

    const handleResetFilter = () => {
        setSearchParams(null);
    }

    const renderResetFilter = () => {
        return (
            <button onClick={()=>handleResetFilter()} className="general-button general-removebutton" >Reset...</button>
        )
    }

    const [ searchParams, setSearchParams ] = useState(null)
    const addSearchParams = (field, searchTerm) => {
        const queryParameter = "?" + field + "=" + searchTerm;
        setSearchParams(queryParameter);
    }

    const fetchPOrders = async(searchObject) => {
        if (!searchObject)
        {
            const data = await fetch(process.env.REACT_APP_API + '/api/purchase-orders', {
                credentials: 'include',
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
            })
    
            const json = await data.json();

            setPOrders(json);
            updateLoadedData(true);
        }
        else
        {
            const data = await fetch(process.env.REACT_APP_API + '/api/purchase-orders' + searchObject, {
                credentials: 'include',
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
            })
    
            const json = await data.json();
    
            setPOrders(json);
            updateLoadedData(true);
        }
    }

    useEffect(() => {
        handleCookieValidation(routeLoginChange);
    },[])

    useEffect(() => {
        fetchPOrders(searchParams)
        .then(() => {
        })
        .catch((err) => {
            console.log(err);
        })
    },[loadedData, searchParams])

    if (loadedData)
    {
        return (
            <div className="grid-container" id="home-content">
                <TitleBar heading={ heading } buttonName={ buttonName } 
                            button1={ handleFilter} button2={ renderResetFilter }
                            cname="infractions-top"
                />
                <span className="login-error" id="home-priviligeerror">{ errors.privilige }</span>
                {seen ? <POFilter togglePopUp={togglePopUp} addSearchParams={addSearchParams} poRecords={pOrders} /> : null}
                <DataBoxView dataSet={pOrders} itemsPerPage={24} mode="porders" />
            </div>
        );
    }
    else
    {
        return(
            <div>
                Loading...
            </div>
        )
    }
}

export default PurchaseOrders;