import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import InfractionForm from '../Infractions/InfractionForm.js';
import { handleItemDelete,
         handleCookieValidation, 
         handleFieldUpdate 
        }  from '../functional/helperFunctions.js';

const InfractionUpdateForm = ({ form }) => { 
    // Sets basic placeholder object before data loads from backend
    const [ currentItem, setCurrentItem ] = useState({})
    const [ errors, setErrors ] = useState({})

    // Sets up redirection routes
    const navigate = useNavigate();
    const routeChange = () => {
        navigate('/court-infractions');
    }
    const routeLoginChange = () => {
        navigate('/login');
    }

    // Updates the k,v pair of a form field an applies formatting
    const onFieldUpdate = (e) => {
        handleFieldUpdate(e, updateState);
    }

    // Updates state of form
    const updateState = (key, value) => {
        setCurrentItem({...currentItem, [key]: value});
    }

    // Calls helper function which returns value to handle redirection/error message
    const onUpdateDelete = async () => {
        console.log('at least we hitting here')

        const redirectPage = await handleItemDelete(currentItem._id, setErrors, '/api/court-infractions/');

        if (redirectPage === 0)
        {
            routeChange();
        }
        else if (redirectPage === 1)
        {
            routeLoginChange();
        }
        else if (redirectPage === 2)
        {
            console.log('Privilige Error')
        }
        else
        {
            console.log('Unknown Update Delete Error')
        }
    }

    // On first load, handle user validation
    useEffect(() => {
        handleCookieValidation(routeLoginChange);
    },[])

    // async PUT request to update record and update error states
    const fetchPUTInfraction = async () =>{
        var errorFlag = false;

        const data = await fetch(process.env.REACT_APP_API + '/api/court-infractions/' + currentItem._id, {
            credentials: 'include',
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(currentItem),
        })

        const json = await data.json();

        if (json.errors)
        {
            errorFlag = true;
            setErrors({});

            if (json.errors.jwt)
            {
                routeLoginChange();
            }
            else if (json.errors.privilige)
            {
                setErrors({ "privilige": "Errors - Your account privilige is too low, contact Site Administrator" })
            }

        }

        if (!errorFlag)
        {
            routeChange();
        }
    }

    // On submit, call async PUT request
    const onUpdateSubmit = () => {
        fetchPUTInfraction();
    }

    // When the form loads in from parent component, sets state
    useEffect(() => {
        setCurrentItem({
            _id: form._id,
            author: form.author,
            createdAt: form.createdAt,
            sport: form.sport,
            p1: form.p1 ? form.p1 : "",
            p2: form.p2 ? form.p2 : null,
            p3: form.p3 ? form.p3 : null,
            p4: form.p4 ? form.p4 : null,
            infraction: form.infraction ? form.infraction : "",
            date: form.date ? form.date : "",
            time: form.time ? form.time : "",
            time2: form.time2 ? form.time2: "",
            court: form.court ? form.court : "",
            notes: form.notes ? form.notes : "",
        })
    },[form])

    return (
        <div className="form-container" id="form-update">
            <InfractionForm handleFieldUpdate={onFieldUpdate} key={ 0 } instance={ 0 } form={currentItem} errors={errors} formType={'update'} />
            <div className="form-instance">
                <input type="button" 
                    onClick={ onUpdateDelete } 
                    value="Delete" 
                    className="general-button form-deletebutton"/>
                <input type="button" 
                    onClick={ onUpdateSubmit } 
                    value="Update" 
                    className="general-button form-updatebutton"/>
            </div>
        </div>
    )
}

export default InfractionUpdateForm;