import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { DataContext } from '../contexts/DataContext.js';

const MemberForm = ({ handleFieldUpdate, instance, form, errors, formType }) => { 
        console.log(form)
        const [ formErrors, setFormErrors ] = useState({
                name: "",
                privilige: ""
        })

        // Data loaded
        const { updateLoadedData } = useContext(DataContext);  

        const handleFormChange = (e) => {
                handleFieldUpdate(e); 
        }

        useEffect(() => {
                updateLoadedData(false);
        }, [form])

        useEffect(()=> {
                if (errors)
                {
                        setFormErrors(errors);
                }
        },[errors])

        return (
        <form className="form-instance" id={"infraction-form" + instance}>
                <h2>Entry #{ instance + 1 }</h2>
                <div className="login-error">{ formErrors.privilige }</div>

                <br />
                <h3>Name</h3>
                <input type="text" 
                        className="form-players" 
                        onChange={ handleFormChange }
                        data-key="name" 
                        data-instance={ instance }
                        value={form.name} />  
                <h3>Email</h3>
                <input type="text" 
                        className="form-players" 
                        onChange={ handleFormChange }
                        data-key="email" 
                        data-instance={ instance }
                        value={form.email} />  
                <div className="login-error">{ formErrors.name }</div>
        </form>
    )
}

export default MemberForm;