import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MemberUpdateForm from '../Members/MemberUpdateForm.js';

const UpdateCV = () => {
    const { id } = useParams();
    const heading = "Update a Member Record";

    const [ form, setForm ] = useState({});
    useEffect(() => {
        fetch(process.env.REACT_APP_API + '/api/members/' + id)
        .then(res => {
            return res.json();
        })
        .then((data) => {
            setForm(data);
        })
    }, [])

    return(
        <div className="grid-container" id="create-form">
            <div className="general-header" id="home-top">
                <h2 className="general-heading">{ heading }</h2>
            </div>            
            <MemberUpdateForm form={form}/> 
        </div>
    )
}

export default UpdateCV;