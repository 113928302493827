import React from 'react';

const TitleBar = ({heading, buttonName, button1, button2, cname}) => {
    return (
        <div className="general-header" id={cname}>
            <h2 className="general-heading">{ heading }</h2>
            <button onClick={()=>button1()} className="general-button home-filterbutton" >{ buttonName }</button>
            { button2() }
        </div>
    )
}

export default TitleBar;