import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import InfractionForm from './InfractionForm.js';
import AlertDialog from '../functional/AlertDialogMUI.js';
import { handleCookieValidation, handleFieldUpdate, getDateAndPONum }  from '../functional/helperFunctions.js';

const CreateCV = () => {
    // Heading
    const heading = "Create a Court Violation";

    // Returns date for use in PO Number creation
    const dateAndPO = getDateAndPONum();
    const todayDate = dateAndPO[0];

    // useState declarations
    const [ errors, setErrors ] = useState({})
    const [ forms, setForms ] = useState({
        sport: "Tennis", date: todayDate,
        });
    
    // Route change declarations
    const navigate = useNavigate();
    const routeChange = () => {
        navigate('/court-infractions');
    }
    const routeLoginChange = () => {
        navigate('/login');
    }
    const routeAddAnotherChange = () => {
        navigate('/infraction-create');
        window.location.reload();
    }

    // passed to helper function to handle format changes to data from event
    const onFieldUpdate = (e) => {
        handleFieldUpdate(e, updateState)
    }
    
    // update state function of form
    const updateState = (key, value) => {
        setForms({...forms, [key]: value})
    }

    // POST request to create court infraction
    // Updates with applicable errors to pass down from form validation
    const fetchPOSTInfraction = async (addAnother) => {
        var errorFlag = false;

        const data = await fetch(process.env.REACT_APP_API + '/api/court-infractions', {
            credentials: 'include',
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(forms),
        })

        const json = await data.json();

        if (json.errors)
        {
            errorFlag = true;
            setErrors({});
            for (const [k, v] of Object.entries(json.errors)) {
                if (k === "p1")
                {
                    setErrors(err => ({...err, [k]: ('The name field is required!')}))
                }
                else if (k === "privilige")
                {
                    setErrors(err => ({...err, [k]: v}))
                }
                else
                {
                    setErrors(err => ({...err, [k]: ('The '+ k +' field is required!')}))
                }
            }
        }

        // If there are errors it will not redirect, otherwise change pages
        if (!errorFlag)
        {
            if (addAnother)
            {
                routeAddAnotherChange();
            }
            else
            {
                routeChange();
            }
        }
    }
    
    // Function to pass for form submit button
    const onFormSubmit = (addAnother) => {
        fetchPOSTInfraction(addAnother);
    }

    // On first render, verify cookie otherwise redirect to login
    useEffect(() => {
        handleCookieValidation(routeLoginChange);
    },[])

    return (
        <div className="grid-container" id="create-form">
            <div className="general-header" id="home-top">
                <h3 className="home-top">{heading}</h3>
            </div>
            <div className="form-container">
                <InfractionForm handleFieldUpdate={onFieldUpdate} key={ 0 } instance={ 0 } form={forms} errors={errors} />
            </div>
            <div className="form-instance" id="infraction-submit">
                <AlertDialog buttonName="submit" 
                            buttonColour="info"
                            handleClickAction={onFormSubmit}
                            mainText={"If you do not wish to submit the form(s), click Cancel."}
                            subBtn1 = "Submit"
                            subBtn2 = "Submit and add new..."  />
            </div>
        </div>
    )
}

export default CreateCV;