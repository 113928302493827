import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertDialog from '../functional/AlertDialogMUI.js';
import POForm from '../PurchaseOrders/POForm.js';
import POReview from '../PurchaseOrders/POReview.js';
import { fetchAlphaNum } from '../functional/helperFunctions.js';
import { UserContext } from '../contexts/UserContext.js';
import { handleCookieValidation, updatePOState, getDateAndPONum } from '../functional/helperFunctions.js';

const CreatePO = () => {
    const heading = "Create a Purchase Order Request";
    const buttonName = "Add..."

    // User Context
    const { userPrefs } = useContext(UserContext);

    
    const dateAndPO = getDateAndPONum(userPrefs.department);
    const todayDate = dateAndPO[0];
    const todayPONum = dateAndPO[1];

    const [ forms, setForms ] = useState({
        date: todayDate, ponum: todayPONum, approved: "false",
        paddress: "", pcity: "", ppostal: "", saddress: "", scity: "",
        spostal: "", lineitems: [{
            quantity: 0,
            description: "",
            unit: 0,
            cost: 0
        }]
    });
    
    // Updates the k,v pair of a form field for a given object
    const onFieldUpdate = (e) => {
        handleNewPOUpdate(e, updateState)
    }

    const handleNewPOUpdate = (e, updateState) => {
        const changeKey = e.target.getAttribute('data-key');
        const changeLineItemKey = e.target.getAttribute('data-lineinstance')
        let changeValue = e.target.value;
    
        if (changeLineItemKey)
        {
            updateState(changeKey, changeValue, changeLineItemKey);
        }
        else
        {
            updateState(changeKey, changeValue);
        }
    }

    const onAddLineItem = () => {
        var formObject = forms
        formObject["lineitems"].push({
            quantity: 0,
            description: "",
            unit: 0,
            cost: 0
        })

        setForms(formObject);
    }

    const onRemoveLineItem = () => {
        var formObject = forms
        if (formObject.lineitems.length > 1){
            formObject["lineitems"].pop()
            setForms(formObject);

            updateState("quantity", formObject["lineitems"][0].quantity, 0, forms, setForms )
        }
        else {
            console.log('Requires at least 1 lineitem')
        }
    }

    const onRemoveSingleItem = (inst) => {
        var formObject = forms
        if (formObject.lineitems.length > 1){
            formObject["lineitems"].splice(inst, 1)
            setForms(formObject);

            updateState("quantity", formObject["lineitems"][0].quantity, 0, forms, setForms )
        }
        else {
            console.log('Requires at least 1 lineitem')
        }  
    }

    const updateState = (key, value, lineInstance) => {
        updatePOState(key, value, lineInstance, forms, setForms);
    }

    const onApprovePO = (key, value) => {
        setForms(form => ({...form, [key]: value}))
    }

    const [ seen, setSeen ] = useState(false);
    const togglePopUp = () => {
        setSeen(false);
    }

    const onReviewPO = () => {
        setSeen(true);
    }

    const [ errors, setErrors ] = useState({})
    const fetchPOSTPO = async (addAnother) => {
        var errorFlag = false;

        const data = await fetch(process.env.REACT_APP_API + '/api/purchase-orders', {
            credentials: 'include',
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(forms),
        })

        const json = await data.json();
        if (json.errors)
        {
            errorFlag = true;
            setErrors({});
            for (const [k, v] of Object.entries(json.errors)) {
                if (k === "pto" || k === "sto")
                {
                    setErrors(err => ({...err, [k]: ('The To field is required!')}))
                }
                else if (k === "privilige")
                {
                    setErrors(err => ({...err, [k]: v}))
                }
                else
                {
                    setErrors(err => ({...err, [k]: ('The '+ k +' field is required!')}))
                }            }
        }
        if (!errorFlag)
        {
            if (addAnother)
            {
                routeAddAnotherChange();
            }
            else
            {
                routeChange();
            }
        }
    }

    const onFormSubmit = (addAnother) => {
        fetchPOSTPO(addAnother);
    }

    const navigate = useNavigate();
    const routeChange = () => {
        navigate('/purchase-orders');
    }
    const routeLoginChange = () => {
        navigate('/login');
    }

    const routeAddAnotherChange = () => {
        navigate('/po-create');
        window.location.reload();
    }

    useEffect(() => {
        handleCookieValidation(routeLoginChange);
        fetchAlphaNum(todayPONum, setForms);
    },[])

    return (
        <div className="po-container">
            <div className="general-header" id="home-top">
                <h3 className="home-top">{heading}</h3>
            </div>
            <div className="poform-container">
                <POForm onFieldUpdate={ onFieldUpdate }
                        key={ 0 } instance={ 0 }
                        handleAddLineItem={ onAddLineItem }
                        handleRemoveLineItem={ onRemoveLineItem }
                        handleRemoveSingleItem={ onRemoveSingleItem }
                        form={forms}
                        errors={errors}
                    />
            </div>
            { seen ? <POReview togglePopUp={togglePopUp} handleApprovePO={onApprovePO} form={forms} /> : null }
            <div className="form-instance" id="po-submit">
            {
                userPrefs.privilige > 1 ? 
                <input type="button" 
                        onClick={ onReviewPO } 
                        value="Review" 
                        className="general-button form-updatebutton"/>
                :
                null
            }
                <AlertDialog buttonName="submit" 
                            buttonColour="info"
                            handleClickAction={onFormSubmit}
                            mainText={"If you do not wish to submit the form(s), click Cancel."}
                            subBtn1 = "Submit"
                            subBtn2 = "Submit and add new..."  />
            </div>

        </div>
    )
}

export default CreatePO