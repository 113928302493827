import React from 'react';
import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AccountBox from '../functional/AccountBox.js';
import headerMenu from '../images/menu-icon.png';
import { UserContext } from '../contexts/UserContext.js';
import { DataContext } from '../contexts/DataContext.js';


const Header = () => {
    // User Context
    const { updateUserPrefs } = useContext(UserContext);

    // Data loaded
    const { updateLoadedData } = useContext(DataContext);
    const onRedirectDataLoad = () => {
        updateLoadedData(false);
    }

    // Setup redirect
    const navigate = useNavigate();
    const routeChange = () => {
        navigate('/login');
    }

    const onLogout = () => {  
        // Fetch request to login use loginDetails state
        fetch(process.env.REACT_APP_API + '/api/logout', {
            method: 'GET',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
        })
        .then(res => {
            return res.json();
        })
        .then((data) => {
            updateUserPrefs({name: "", isLoggedOn: false})
            routeChange();
        })
    }

    const [ navbar, setNavbar ] = useState(false);
    const showNavbar = () => {
        setNavbar(!navbar)
    }

    return (
        <nav className="nav">
            <div className="header">
                <div className="navbar">
                    <Link to="#" onClick={ showNavbar} className="menu-bars">
                        <img src={headerMenu} className="header-menu" alt="menu button" />
                    </Link>
                </div>
                <nav className={ navbar ? "nav-menu active" : "nav-menu"}>
                    <ul className="nav-menu-items" onClick={ showNavbar }>
                        <li className="navbar-toggle"><Link to="#" className="menu-bars">X</Link></li>
                        <li className="nav-text"><Link to={"/"}>Home</Link></li>
                        <li className="nav-text"><Link to={"login"}>Login</Link></li>
                        <li className="nav-text"><Link to={"members"}>Members</Link></li>
                        <li className="nav-text"><Link to={"member-create"}>Create Member</Link></li>
                        <li className="nav-text"><Link to={"court-infractions"}>Infractions</Link></li>
                        <li className="nav-text"><Link to={"infraction-create"}>Create Entry</Link></li>
                        <li className="nav-text"><Link to={"purchase-orders"}>Purchase Orders</Link></li>
                        <li className="nav-text"><Link to={"po-create"}>Create PO</Link></li>
                    </ul>
                </nav>
                <Link to="/" onClick={onRedirectDataLoad} className="header-link">BRWS</Link>
                <AccountBox onLogout={onLogout} />
            </div>
        </nav>
        
    )
}

export default Header;