import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { DataContext } from '../contexts/DataContext.js';
// import CreateMember from '../Members/CreateMember.js';
import SelectTimeField from '../functional/SelectTimeField.js';
import PlayerItem from '../Infractions/PlayerItem.js';
import { fetchMemberNames, handleInfractionHighlight  } from '../functional/helperFunctions.js';


const InfractionForm = ({ handleFieldUpdate, instance, form, errors, formType }) => { 
        // Data loaded
        const { updateLoadedData } = useContext(DataContext);  

        // useState declarations
        const [ memberNames, setMemberNames] = useState([])
        const [ playerItems, setPlayerItems ] = useState([0])
        const [ newMember, setNewMember ] = useState(false)
        const [ formErrors, setFormErrors ] = useState({
                p1: "",
                infraction: "",
                date: "",
                court: "",
                time: ""
        })
        
        const [ buttonStyleObject, setButtonStyleObject ] = useState({
                "Late Cancel": "general-button form-updatebutton normal",
                "No Show": "general-button form-updatebutton normal",
                "Guest TBD": "general-button form-updatebutton normal",
                "Singles To Doubles": "general-button form-updatebutton large",
                "Guest Name": "general-button form-updatebutton normal",
                "2 Bookings":"general-button form-updatebutton normal",
        })
        
        // Adding a player to playerItem qantity state
        const onAddPlayerItem = () => {
                if (playerItems.length < 4)
                {
                        const lastKey = playerItems[playerItems.length - 1];
                        setPlayerItems(playerItems => ([...playerItems, lastKey + 1]));
                }
                else
                {
                        console.log('Error: Maximum players set to 4')
                }
        }

        // Removes the last form and any data from that form
        const onRemovePlayerItem = () => {
                console.log(playerItems.length)
                if (playerItems.length > 1)
                {
                        const lastKey = playerItems[playerItems.length - 1];
                        setPlayerItems(playerItems.filter(keyVal => keyVal !== lastKey))
                }
                else
                {
                        console.log('Error: Requires at least 1 player')
                }

        }

        // Calls helper function to load the update side of the form and button styling 
        const initialFormLoad = (form) => {
                handleInfractionHighlight({"infraction": form.infraction}, setButtonStyleObject, buttonStyleObject)
        }

        // Calls helper function to update form state and button highlighting on selected option
        const handleFormChange = (e) => {
                handleFieldUpdate(e); 
                
                const changeKey = e.target.getAttribute('data-key');
                const changeValue = e.target.value;

                handleInfractionHighlight({[changeKey]: changeValue}, setButtonStyleObject, buttonStyleObject)
        }

        // const updateNewMember = () => {
        //         setNewMember(true)
        // }

        // Calls initial form load and sets update loaded data contex to false
        // Only changes on form changes
        useEffect(() => {
                initialFormLoad(form);
                updateLoadedData(false);
        }, [form])

        // Update form error state to pass to form to display error messages
        useEffect(()=> {
                if (errors)
                {
                        setFormErrors(errors);
                }
        },[errors])

        // On initial page load, if status type is to update a record, show all player item lines
        useEffect(() => {
                if (formType === "update") 
                {
                        setPlayerItems([0, 1, 2, 3])
                }
        },[])

        // On initial page load, call helper function to pull names for name selection input
        useEffect(() => {
                fetchMemberNames(setMemberNames);
                if (newMember == true) {
                        console.log('aight den')
                }
                setNewMember(false);
        }, [newMember])

        return (
        <>
                <form className="form-instance" id={"infraction-form" + instance}>
                        <h2>Entry #{ instance + 1 }</h2>
                        <div className="login-error">{ formErrors.privilige }</div>
                        <br />
                        <h3>Names</h3>
                        { playerItems.map((numInst)=> (
                                <PlayerItem handleFormChange={handleFieldUpdate}
                                        key={ numInst } instance={ instance } 
                                        form={form} playerNum={ numInst }
                                        memberList={memberNames}
                                />
                        ))}
                        <br />
                        <a href="#" onClick={ onAddPlayerItem } id="infraction-additem" 
                        className="general-button">
                                Add Player
                        </a>
                        <a href="#" onClick={ onRemovePlayerItem } id="infraction-removeitem" 
                        className="general-button">
                                Remove Player
                        </a>
                        <div className="login-error">{ formErrors.p1 }</div>
                        <h3>Infraction</h3>
                        <input type="button" 
                                className={ buttonStyleObject["Late Cancel"]} 
                                value="Late Cancel" 
                                onClick={ handleFormChange }
                                data-key="infraction" 
                                data-instance={ instance } />
                        <input type="button" 
                                className={ buttonStyleObject["No Show"]} 
                                value="No Show" 
                                onClick={ handleFormChange }
                                data-key="infraction"
                                data-instance={ instance } />
                        <input type="button" 
                                className={ buttonStyleObject["2 Bookings"]} 
                                value="2 Bookings"
                                onClick={ handleFormChange }
                                data-key="infraction"
                                data-instance={ instance } />
                        <br />
                        <input type="button" 
                                className={ buttonStyleObject["Singles To Doubles"]} 
                                value="Singles To Doubles"
                                onClick={ handleFormChange }
                                data-key="infraction"
                                data-instance={ instance } />
                        <input type="button" 
                                className={ buttonStyleObject["Guest Name"]} 
                                value="Guest Name"
                                onClick={ handleFormChange }
                                data-key="infraction"
                                data-instance={ instance } />
                        <div className="login-error">{ formErrors.infraction }</div>
                        <h3>Date</h3>
                        <input type="date"  
                                onChange={ handleFormChange }
                                data-key="date"
                                data-instance={ instance }
                                value={ form.date }
                                min="2019-01-01"
                                max="2099-12-31" />
                        <div className="login-error">{ formErrors.date }</div>
                        <h3>Time</h3>
                        <SelectTimeField handleFormChange={handleFormChange} form={form} instance={instance} />
                        <div className="login-error">{ formErrors.time }</div>
                        <h3>Notes</h3>
                        <textarea id="notes"
                                onChange={ handleFormChange }
                                data-key="notes"
                                data-instance={ instance }
                                value={form.notes} />
                </form>

                {/* <CreateMember formType="cv" newMember={ updateNewMember } /> */}
        </>
    )
}

export default InfractionForm;