import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import DataBoxView from '../functional/DataBoxView.js';
import { handleCookieValidation }  from '../functional/helperFunctions.js';
import { DataContext } from '../contexts/DataContext.js';

const Members = () => {
    const heading = "Member Records";

    // Data loaded
    const { loadedData, updateLoadedData } = useContext(DataContext);

    // Setup redirect
    const navigate = useNavigate();
    const routeLoginChange = () => {
        navigate('/login');
    }

    const [ errors, setErrors ] = useState({})
    const [ members, setMembers ] = useState([]);
    const fetchMembers = async () => {
        const data = await fetch(process.env.REACT_APP_API + '/api/members', {
            credentials: 'include',
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        })

        const json = await data.json();

        setMembers(json);
        updateLoadedData(true);
    }

    useEffect(() => {
        handleCookieValidation(routeLoginChange);
    })

    useEffect(() => {
        fetchMembers()
        .then(() => {})
        .catch((err) => {
            console.log(err);
        })
    },[loadedData])

    if (loadedData)
    {
        return (
            <div className="grid-container" id="home-content">
                <div className="general-header" id="home-top">
                    <h3 className="home-top">{heading}</h3>
                </div>
                <span className="login-error" id="home-priviligeerror">{ errors.privilige }</span>
                <DataBoxView dataSet={members} itemsPerPage={20} mode="members" />
            </div>
        );
    }
    else
    {
        return(
            <div>
                Loading...
            </div>
        )
    }
}

export default Members;