import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import InfractionUpdateForm from '../Infractions/InfractionUpdateForm.js';

const UpdateCV = () => {
    // Grab URL parameter for GET request on single record
    const { id } = useParams();

    const heading = "Update a Court Violation";
    
    // useState declarations
    const [ loading, setLoading ] = useState(true)
    const [ form, setForm ] = useState({});
    
    // async GET request for single record and update state
    const fetchSingleInfraction = async () => {    
        const data = await fetch(process.env.REACT_APP_API + '/api/court-infractions/'+ id, {
            credentials: 'include',
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        })
    
        const json = await data.json();
    
        setForm(json);
        setLoading(false);
    }

    // Calls async GET request
    useEffect(() => {
        fetchSingleInfraction(setForm)
    }, [])

    if (loading === true)
    {
        return(
            <div>
                loading...
            </div>
        )
    }
    else
    {
        return(
            <div className="grid-container" id="create-form">
                <div className="general-header" id="home-top">
                    <h2 className="general-heading">{ heading }</h2>
                </div>            
                <InfractionUpdateForm form={form}/> 
            </div>
        )
    }
}

export default UpdateCV;