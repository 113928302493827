import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { DataContext } from '../contexts/DataContext.js';


const ProgramForm = ({ onFieldUpdate, form, errors }) => {
    const { updateLoadedData } = useContext(DataContext);

    const [ formErrors, setFormErrors ] = useState({})

    const handleFieldUpdate = (e) => {
        onFieldUpdate(e);
    }

    useEffect(() => {
        updateLoadedData(false);
    },[])

    useEffect(() => {
        //Nothing currently needs to be rendered? What happens when the form updates
    },[form])

    useEffect(()=> {
        if (errors)
        {
                setFormErrors(errors);
        }
    },[errors])

    return (
        <form className="form-instance" id="program-form">
            <h2 id="programform-heading">
                Program Form
            </h2>
            <div className="login-error" id="error-privilige">{ formErrors.privilige }</div>

            <label htmlFor="title" id="label-title" className="programform-label">Title</label>
            <input type="text" 
                    id="progform-title"
                    className="poform-xlarge" 
                    onChange={ handleFieldUpdate }
                    data-key="title"
                    value={form.title} />

            <label htmlFor="description" id="label-description" className="programform-label">Description</label>
            <textarea 
                    id="progform-description"
                    className="progform-xlarge" 
                    onChange={ handleFieldUpdate }
                    data-key="description"
                    value={form.description} />
            <div className="general-error" id="error-paddress">{ formErrors.description }</div>

            <label htmlFor="date" id="label-date" className="programform-label">Date (YYYY-MM-DD)</label>
            <input  type="text" 
                    id="progform-date"
                    className="poform-xlarge" 
                    onChange={ handleFieldUpdate }
                    data-key="date"
                    value={form.date} />
            <div className="general-error" id="error-pto">{ formErrors.date }</div>

            <label htmlFor="participants" id="label-participants" className="programform-label">Participants</label>
            <input type="number" 
                    id="progform-participants"
                    className="poform-xlarge" 
                    onChange={ handleFieldUpdate }
                    data-key="participants"                   
                    value={form.participants} />
            <div className="general-error" id="error-paddress">{ formErrors.participants }</div>

            <label htmlFor="dTR" id="label-daysToRegister" className="programform-label">Days to Register</label>
            <input type="number" 
                    id="progform-daysToRegister"
                    className="poform-xlarge" 
                    onChange={ handleFieldUpdate }
                    data-key="daysToRegister"                   
                    value={form.daysToRegister} />
            <div className="general-error" id="error-paddress">{ formErrors.daysToRegister }</div>

            <label htmlFor="email" id="label-email" className="programform-label">Email</label>
            <input type="text" 
                    id="progform-email"
                    className="poform-xlarge" 
                    onChange={ handleFieldUpdate }
                    data-key="recipientEmail"                   
                    value={form.recipientEmail} />
            <div className="general-error" id="error-paddress">{ formErrors.email }</div>
        </form>
    )
}

export default ProgramForm;