import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver'
import POForm from '../PurchaseOrders/POForm.js';
import POReview from '../PurchaseOrders/POReview.js';
import AlertDialog from '../functional/AlertDialogMUI.js';
import { DataContext } from '../contexts/DataContext.js';
import { UserContext } from '../contexts/UserContext.js';
import { handleItemDelete, 
         handleCookieValidation,
         handleFieldUpdate,
         updatePOState
        } from '../functional/helperFunctions.js';

const POUpdateForm = ({ form }) => {
    // Data loaded
    const { loadedData, updateLoadedData } = useContext(DataContext);
    // User Context
    const { userPrefs } = useContext(UserContext);

    // Updates the k,v pair of a form field for a given object
    const onFieldUpdate = (e) => {
        handleFieldUpdate(e, updateState);
    }

    const updateState = (key, value, lineInstance) => {
        updatePOState(key, value, lineInstance, currentItem, setCurrentItem);
    }

    const onAddLineItem = () => {
        var formObject = form
        formObject["lineitems"].push({
            quantity: 0,
            description: "",
            unit: 0,
            cost: 0
        })
        //setCurrentItem(formObject);
        updateState("quantity", formObject["lineitems"][0].quantity, 0, currentItem, setCurrentItem )

    }

    const onRemoveLineItem = () => {
        var formObject = currentItem
        if (formObject.lineitems.length > 1){
            formObject["lineitems"].pop()
            setCurrentItem(formObject);

            updateState("quantity", formObject["lineitems"][0].quantity, 0, currentItem, setCurrentItem )
        }
        else {
            console.log('Requires at least 1 lineitem')
        }
    }

    const onRemoveSingleItem = (inst) => {
        var formObject = currentItem
        if (formObject.lineitems.length > 1){
            formObject["lineitems"].splice(inst, 1)
            setCurrentItem(formObject);

            updateState("quantity", formObject["lineitems"][0].quantity, 0, currentItem, setCurrentItem )
        }
        else {
            console.log('Requires at least 1 lineitem')
        }  
    }

    const onUpdateDelete = async () => {
        const redirectPage = await handleItemDelete(currentItem._id, setErrors, '/api/purchase-orders/');
        console.log(redirectPage)
        if (redirectPage === 0)
        {
            routeChange();
        }
        else if (redirectPage === 1)
        {
            routeLoginChange();
        }
        else if (redirectPage === 2)
        {
            console.log('Privilige Error')
        }
        else
        {
            console.log('Unknown Update Delete Error')
        }
    }

    useEffect(() => {
        handleCookieValidation(routeLoginChange);
    },[])

    const navigate = useNavigate();
    const routeChange = () => {
        navigate('/purchase-orders');
    }
    const routeLoginChange = () => {
        navigate('/login');
    }

    //Download PDF from API call
    const createDownloadPDF = () => {
        fetch(process.env.REACT_APP_API + '/api/pdf-po', {
            credentials: 'include',
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(currentItem)
        })
        .then((response) => response.blob())
        .then((res) => {
            const pdfBlob = new Blob([res], { type: 'application/pdf' });
            saveAs(pdfBlob, `PO#${currentItem.ponum + currentItem.polet}.pdf`)
        }).catch((err)=> {
            console.log(err)
        })
    }

    const [ errors, setErrors ] = useState({})
    const fetchPUTInfraction = async (routeChange) =>{
        var errorFlag = false;
        var tempObject = currentItem;

        if (form.lineitems)
        {
            for (var i = 0; i < lineItemObject.length; i++)
            {
                tempObject.lineitems[i].unit = lineItemObject[i].unit * 100;
                tempObject.lineitems[i].cost = lineItemObject[i].cost * 100;
            }
        }

        tempObject.hst = tempObject.hst * 100
        tempObject.subtotal = tempObject.subtotal * 100
        tempObject.total = tempObject.total * 100

        const data = await fetch(process.env.REACT_APP_API + '/api/purchase-orders/' + currentItem._id, {
            credentials: 'include',
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(tempObject),
        })

        const json = await data.json();
        console.log(json)
        if (json.errors)
        {
            errorFlag = true;
            setErrors({});

            if (json.errors.jwt)
            {
                routeLoginChange();
            }
            else if (json.errors.privilige)
            {
                setErrors({ "privilige": json.errors.privilige })
            }

        }

        if (!errorFlag)
        {
            routeChange();
        }
    }

    const onUpdateSubmit = () => {
        fetchPUTInfraction(routeChange);
    }

    const onApprovePO = (key, value) => {
        setCurrentItem(form => ({...form, [key]: value}))
    }

    const [ seen, setSeen ] = useState(false);
    const togglePopUp = () => {
        setSeen(false);
    }

    const onReviewPO = () => {
        setSeen(true);
    }

    // Sets basic placeholder object before data loads from backend
    const [ currentItem, setCurrentItem ] = useState({lineitems: [{
            quantity: "",
            description: "",
            unit: "",
            cost: ""
        }], approved: false
    })

    const [ lineItemObject, setLineItemObject ] = useState({})
    // When the form loads in from parent component, sets state
    useEffect(() => {
        if (form.lineitems)
        {
            var tempObject = form.lineitems;
            for (var i = 0; i < form.lineitems.length; i++)
            {
                tempObject[i].unit = form.lineitems[i].unit["$numberDecimal"] / 100;
                tempObject[i].cost = form.lineitems[i].cost["$numberDecimal"] / 100;
            }
            
            setLineItemObject(tempObject)
        }
    },[form])

    useEffect(() => {
        setCurrentItem({
            _id: form._id,
            title: form.title,
            author: form.author,
            createdAt: form.createdAt,
            date: form.date ? form.date : "",
            ponum: form.ponum ? form.ponum : "",
            polet: form.polet ? form.polet : "",
            gl: form.gl ? form.gl : "",
            pto: form.pto ? form.pto : "",
            paddress: form.paddress ? form.paddress : "",
            pcity: form.pcity ? form.pcity : "",
            ppostal: form.ppostal ? form.ppostal : "",
            sto: form.sto ? form.sto : "",
            saddress: form.saddress ? form.saddress : "",
            scity: form.scity ? form.scity : "",
            spostal: form.spostal ? form.spostal : "",
            lineitems: form.lineitems ? lineItemObject : [{
                quantity: 0,
                description: "",
                unit: 0,
                cost: 0
            }],
            approved: form.approved ? form.approved : false,
            hst: form.hst ? (form.hst / 100) : 0,
            subtotal: form.subtotal ? (form.subtotal / 100) : 0,
            total: form.total ? (form.total / 100) : 0,
        })

        updateLoadedData(true)
    }, [lineItemObject])

    if (loadedData)
    {
        return (
            <>
                <div className="form-container" id="form-update">
                    <POForm onFieldUpdate={onFieldUpdate}
                            handleAddLineItem={onAddLineItem}
                            handleRemoveLineItem={ onRemoveLineItem }
                            handleRemoveSingleItem={ onRemoveSingleItem } 
                            key={ 0 } instance={ 0 } errors={errors} 
                            form={currentItem} formType={'update'} />
                </div>
                { seen ? <POReview togglePopUp={togglePopUp} handleApprovePO={onApprovePO} form={currentItem} /> : null }
                <div className="form-instance" id="po-updatesubmit">
                    {
                        userPrefs.privilige > 1 ? 
                        <input type="button" 
                        onClick={ onReviewPO } 
                        value="Review" 
                        className="general-button form-updatebutton"/>
                        :
                        null
                    }
                    <input type="button" 
                        onClick={ onUpdateSubmit } 
                        value="Update" 
                        className="general-button form-updatebutton"/>
                    <AlertDialog buttonName="Delete" 
                            buttonColour="error"
                            handleClickAction={onUpdateDelete}
                            mainText={"If you do not wish to delete the form, click Cancel."}
                            subBtn1 = "Delete"
                            subBtn2 = "Submit and add new..." />
                    <input type="button" 
                        onClick={ createDownloadPDF } 
                        value="Download" 
                        className="general-button form-updatebutton"/>
                </div>   
            </>
        )
    }
    else
    {
        return (
            <div>
                Loading...
            </div>
        )
    }
    

}

export default POUpdateForm;