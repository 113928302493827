import React from 'react';

const SelectTimeField = ({handleFormChange, form, instance}) => {
    const timeSelections = ["6:00 AM", "7:00 AM", "8:00 AM", "9:00 AM", "10:00 AM", "11:00 AM",
                            "12:15 PM", "1:15 PM", "2:15 PM", "3:30 PM", "4:30 PM", "5:30 PM",
                             "6:30 PM", "7:30 PM", "8:30 PM", "9:30 PM"]

    if (form.infraction == "2 Bookings") {
        return (
            <>
                <select name="time" data-key="time" 
                value={form.time} onChange={handleFormChange}
                data-instance={instance}>
                    <option value="" defaultValue>Choose Time...</option>
                    { timeSelections.map((timeSlot) => (
                        <option key={timeSlot} value={timeSlot}>{timeSlot}</option>
                    ))}
                </select>
                <br></br>
                <select name="time2" data-key="time2" 
                value={form.time2} onChange={handleFormChange}
                data-instance={instance}>
                    <option value="" defaultValue>Choose Time...</option>
                    { timeSelections.map((timeSlot) => (
                        <option key={timeSlot} value={timeSlot}>{timeSlot}</option>
                    ))}
                </select>
            </>
        )
    }
    else {
        return (
            <select name="time" data-key="time" 
            value={form.time} onChange={handleFormChange}
            data-instance={instance}>
                <option value="" defaultValue>Choose Time...</option>
                { timeSelections.map((timeSlot) => (
                    <option key={timeSlot} value={timeSlot}>{timeSlot}</option>
                ))}
            </select>
        )
    }

}

export default SelectTimeField;