import React from 'react';
import { Link } from 'react-router-dom'
import AlertDialog from '../functional/AlertDialogMUI.js'

const InfractionsTable = ({currentData, extraFunc, extraFunc2}) => {
    const apiLink = "/court-infractions/";

    return (
        <table className="home-table" id="home-infractiontable">
            <tbody>
                <tr>
                    <td className="home-tableheading">Player 1</td>
                    <td className="home-tableheading">Date</td>
                    <td className="home-tableheading">Time</td>
                    <td className="home-tableheading">Infraction</td>
                    <td className="home-tableheading">Notes</td>
                    <td className="home-tableheading">Update</td>
                    <td className="home-tableheading">Email</td>

                </tr>
                { currentData.map((rec) => (
                    <tr key={rec._id}>
                        <td>{rec.p1.name}</td>
                        <td>{rec.date}</td>
                        <td>{rec.time}</td>
                        <td>{rec.infraction}</td>
                        <td className="home-tablenotes">{rec.notes}</td>
                        <td>
                            <Link to={ apiLink + rec._id } className="general-button home-updatebutton">Update</Link>
                        </td>
                        <td>
                            <input type="checkbox" value={[rec._id, rec.date, rec.time, rec.infraction]} onClick={extraFunc}></input>
                        </td>
                    </tr>
                ))}  
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="home-tableheading">
                        <AlertDialog buttonName="Email" 
                            buttonColour="info"
                            handleClickAction={extraFunc2}
                            mainText={"If you do not wish to send emails to the selected recipients, click Cancel."}
                            subBtn1 = "Send Emails" />
                    </td>
                </tr>   
            </tbody>       
        </table>
    )
}

export default InfractionsTable;