import React from 'react';

import { useState, useContext } from 'react';
import loginImage from '../images/tennis.jpg';
import { useNavigate, Link } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext.js';
import { DataContext } from '../contexts/DataContext.js';


const Login = () => {
    //User Context
    const { updateUserPrefs } = useContext(UserContext);
    const { updateLoadedData } = useContext(DataContext);

    // Setup redirect
    const navigate = useNavigate();
    const routeChange = () => {
        navigate('/');
    }
    const routeSignupChange = () => {
        navigate('/signup');
    }

    // Updates the k,v pair of a form field for a given object
    const [ loginDetails, setLoginDetails ] = useState({});
    const onFieldUpdate = (e) => {
        const changeKey = e.target.getAttribute('data-key');
        const changeValue = e.target.value;

        const formObject = loginDetails;
        formObject[changeKey]=changeValue
        setLoginDetails(formObject)
    }

    const [ errors, setErrors ] = useState({})
    const onLoginSubmit = async () => {
        // Fetch request to login use loginDetails state
        setErrors({});

        // Fetch request to login use loginDetails state
        fetchLogin();
    }

    const fetchLogin = async () => {
        const data = await fetch(process.env.REACT_APP_API + '/api/login', {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(loginDetails),
        })

        const json = await data.json();

        if (json.errors)
        {
            setErrors({  
                name: json.errors.name, 
                email: json.errors.email, 
                password: json.errors.password
            });   
        }
        else
        {
            updateUserPrefs({name: json.name, isLoggedOn: json.isLoggedOn, 
                             privilige: json.privilige, department: json.department})
            updateLoadedData(false)
            routeChange();
        }
    }

    const onForgotPassword = () => {
        // Fetch request to reset password
        navigate('/forgot-password');
    }

    useState(() => {

    },[errors])

    return (
        <div className="grid-container" >
            <div className="login-container" id="login-landing">
                <img src={ loginImage } className="login-image" alt="Hoi Pham on Unsplash"/>
                <form className="login-box">
                    <h3 className="login-heading">Login</h3>
                    <input type="text" 
                        className="login-input" 
                        placeholder="Email" 
                        onChange={ onFieldUpdate }
                        data-key="email" />
                    <div className="login-error">{ errors.email }</div>
                    <input type="password" 
                        autoComplete="on"
                        name="current-password"
                        id="current-password"
                        className="login-input" 
                        placeholder="Password"
                        onChange={ onFieldUpdate }
                        data-key="password" />
                    <div className="login-error">{ errors.password }</div>
                    <input type="button" 
                        onClick={ onLoginSubmit } 
                        value="Log In" 
                        className="general-button login-button login-submit"/>
                    <div className="login-orbox">
                        <div className="login-line"></div>
                        <span className="login-ortext">OR</span>
                        <div className="login-line"></div>
                    </div>
                    <input type="button" 
                        onClick={ onForgotPassword } 
                        value="Forgot Password?" 
                        className="general-button login-button login-forgot"/>
                    <input type="button" 
                        onClick={ routeSignupChange } 
                        value="Don't have an account?" 
                        className="general-button login-button login-submit"/>
                </form>
            </div>
        </div>
    )
}

export default Login;