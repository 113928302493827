import React from 'react';
import { Link } from 'react-router-dom'

const PurchaseOrdersTable = ({currentData, apiLink}) => {
    return (
        <table className="home-table" id="home-infractiontable">
                <tbody>
                    <tr>
                        <td className="home-tableheading">Status</td>
                        <td className="home-tableheading">Date</td>
                        <td className="home-tableheading">PO Number</td>
                        <td className="home-tableheading">GL Number</td>
                        <td className="home-tableheading">Payee Name</td>
                        <td className="home-tableheading">Supplier Name</td>
                        <td className="home-tableheading">Author</td>
                        <td className="home-tableheading">Total</td>
                        <td className="home-tableheading">Update</td>
                    </tr>
                    { currentData.map((rec) => (
                        <tr key={rec._id}>
                            <td>{rec.approved ? "APPROVED": "NOT APPROVED"}</td> 
                            <td>{rec.date}</td> 
                            <td>{rec.ponum + rec.polet}</td>
                            <td>{rec.gl}</td>
                            <td>{rec.pto}</td>
                            <td>{rec.sto}</td>
                            <td>{rec.author }</td>
                            <td>${rec.total / 100 }</td>
                            <td>
                                <Link to={ apiLink + rec._id } className="general-button home-updatebutton">Update</Link>
                            </td>
                        </tr>
                    ))}     
                </tbody>       
            </table>
    )
}

export default PurchaseOrdersTable;