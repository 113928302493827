import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertDialog from '../functional/AlertDialogMUI.js';
import ProgramForm from '../Programs/ProgramForm.js';
import { UserContext } from '../contexts/UserContext.js';
import { handleCookieValidation, handleFieldUpdate } from '../functional/helperFunctions.js';

const CreatePrograms = () => {
    const heading = "Create a Programming Record";
    const buttonName = "Add..."

    // User Context
    const { userPrefs } = useContext(UserContext);

    //Refactor to provide current date
    const [ forms, setForms ] = useState({date: ""});
    
    // Updates the k,v pair of a form field for a given object
    const onFieldUpdate = (e) => {
        handleFieldUpdate(e, updateState)
    }

    // update state function of form
    const updateState = (key, value) => {
        setForms({...forms, [key]: value})
    }

    const [ errors, setErrors ] = useState({})
    const fetchPOSTProgram = async (addAnother) => {
        console.log(forms);
        var errorFlag = false;

        const data = await fetch(process.env.REACT_APP_API + '/api/programs', {
            credentials: 'include',
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(forms),
        })

        const json = await data.json();
        if (json.errors)
        {
            errorFlag = true;
            setErrors({});
            for (const [k, v] of Object.entries(json.errors)) {
                if (k === "privilige")
                {
                    setErrors(err => ({...err, [k]: v}))
                }
                else
                {
                    setErrors(err => ({...err, [k]: ('The '+ k +' field is required!')}))
                }            
            }
        }
        if (!errorFlag)
        {
            if (addAnother)
            {
                routeAddAnotherChange();
            }
            else
            {
                routeChange();
            }
        }
    }

    const onFormSubmit = (addAnother) => {
        console.log('creating....')
        fetchPOSTProgram(addAnother);
    }

    const navigate = useNavigate();
    const routeChange = () => {
        navigate('/programs');
    }
    const routeLoginChange = () => {
        navigate('/login');
    }

    const routeAddAnotherChange = () => {
        navigate('/programs-create');
        window.location.reload();
    }

    useEffect(() => {
        handleCookieValidation(routeLoginChange);
    },[])

    return (
        <div className="program-container">
            <div className="general-header" id="home-top">
                <h3 className="home-top">{heading}</h3>
            </div>
            <div className="programform-container">
                <ProgramForm onFieldUpdate={ onFieldUpdate }
                        key={ 0 } instance={ 0 }
                        form={forms}
                        errors={errors}
                    />
            </div>
            <div className="form-instance" id="program-submit">
            <AlertDialog buttonName="submit" 
                        buttonColour="info"
                        handleClickAction={onFormSubmit}
                        mainText={"If you do not wish to submit the form(s), click Cancel."}
                        subBtn1 = "Submit"
                        subBtn2 = "Submit and add new..."  />
            </div>

        </div>
    )
}

export default CreatePrograms;