import React from 'react';
import { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { handleCookieValidation } from '../functional/helperFunctions.js';

const Home = () => {
    // Setup redirect
    const navigate = useNavigate();
    const routeLoginChange = () => {
        navigate('/login');
    }
    
    useEffect(() => {
        handleCookieValidation(routeLoginChange);
    },[])

    return (
        <div className="general-flexbox">
            <Link to={"members"} className="general-linkbox">
                <span className="general-homelink">
                    Members
                </span>
            </Link>
            <Link to={"court-infractions"} className="general-linkbox">
                <span className="general-homelink">
                    Infractions
                </span>
            </Link>
            <Link to={"purchase-orders"} className="general-linkbox">
                <span className="general-homelink">
                    Purchase Orders
                </span>
            </Link>
            <Link to={"programs"} className="general-linkbox">
                <span className="general-homelink">
                    Programs
                </span>
            </Link>
            <Link to={"member-create"} className="general-linkbox general-create">
                <span className="general-homelink">
                    Create Member
                </span>
            </Link>
            <Link to={"infraction-create"} className="general-linkbox general-create">
                <span className="general-homelink">
                    Create Infraction
                </span>
            </Link>
            <Link to={"po-create"} className="general-linkbox general-create">
                <span className="general-homelink">
                    Create PO
                </span>
            </Link>
            <Link to={"programs-create"} className="general-linkbox general-create">
                <span className="general-homelink">
                    Create Program
                </span>
            </Link>
        </div>
    )
}

export default Home;