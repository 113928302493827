import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import TitleBar from '../General/TitleBar.js';
import DataBoxView from '../functional/DataBoxView.js';
import CVFilter from '../Infractions/CVFilter.js';
import { handleItemDelete, handleCookieValidation }  from '../functional/helperFunctions.js';
import { DataContext } from '../contexts/DataContext.js';

const Infractions = () => {
    // Data loaded
    const { loadedData, updateLoadedData } = useContext(DataContext);

    const heading = "Court Violation Records";
    const buttonName = "Filter..." 

    // useState declarations
    const [ infractions, setInfractions ] = useState([]);
    const [ errors, setErrors] = useState({})
    const [ seen, setSeen ] = useState(false);
    const [ searchParams, setSearchParams ] = useState(null)
    // {mid, date, time, infraction}
    const [ emailList, setEmailList ] = useState([])

    // Control email blast list to send to server
    const updateEmailList = (e) => {
        const entry = (e.target.value).split(',')
        console.log(entry)
        setEmailList(currentList => [...currentList, entry])
    }

    // POST request to send email blast
    const fetchEmailBlast = async () => {
        const data = await fetch(process.env.REACT_APP_API + '/api/court-infractions/eblast', {
            credentials: 'include',
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(emailList),
        })

        const json = await data.json();

        setEmailList([])
        console.log('after eblast ideally', emailList)
    }

    // Setup redirect
    const navigate = useNavigate();
    const routeLoginChange = () => {
        navigate('/login');
    }

    // toggles seen useState to false which hides filter pop up box
    const togglePopUp = () => {
        setSeen(false);
    }

    // toggles seen useState to true which shows filter pop up box
    const handleFilter = () => {
        setSeen(true);
    }

    // resets search parameters to null which pulls all records instead of filtered result
    const handleResetFilter = () => {
        setSearchParams(null);
    }

    const renderResetFilter = () => {
        return (
            <button onClick={()=>handleResetFilter()} className="general-button general-removebutton" >Reset...</button>
        )
    }

    // Formats user filter input to query parameter for api call and updates state
    const addSearchParams = (field, searchTerm) => {
        const queryParameter = "?" + field + "=" + searchTerm;
        setSearchParams(queryParameter);
    }

    // GET request for infraction records
    const fetchInfractions = async (searchObject) => {
        if (!searchObject)
        {
            const data = await fetch(process.env.REACT_APP_API + '/api/court-infractions', {
                credentials: 'include',
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
            })
    
            const json = await data.json();
    
            setInfractions(json);
            updateLoadedData(true);
        }
        else
        {
            const data = await fetch(process.env.REACT_APP_API + '/api/court-infractions' + searchObject, {
                credentials: 'include',
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
            })
    
            const json = await data.json();
    
            setInfractions(json);
            updateLoadedData(true);
        }
    }

    // On initial load, validates user cookie, if invalid redirects to login
    useEffect(() => {
        handleCookieValidation(routeLoginChange);
    },[])

    // Calls GET request with search parameters if any
    useEffect(() => {
        fetchInfractions(searchParams)
        .then(() => {
        })
        .catch((err) => {
            console.log(err);
        })
    },[loadedData, searchParams])

    if (loadedData)
    {
        return (
            <div className="grid-container" id="home-content">
                <TitleBar heading={ heading } buttonName={ buttonName } 
                            button1={ handleFilter} button2={ renderResetFilter }
                            cname="infractions-top"
                />
                <span className="login-error" id="home-priviligeerror">{ errors.privilige }</span>
                {seen ? <CVFilter togglePopUp={togglePopUp} addSearchParams={addSearchParams} /> : null}
                <DataBoxView dataSet={infractions} itemsPerPage={20} errors={errors} mode="infractions" extraFunc={updateEmailList} extraFunc2={fetchEmailBlast} />
            </div>
        );
    }
    else
    {
        return(
            <div>
                Loading...
            </div>
        )
    }
}

export default Infractions;