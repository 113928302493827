import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { DataContext } from '../contexts/DataContext.js';
import POLineItem from './POLineItem.js';


const POForm = ({ onFieldUpdate, handleAddLineItem, handleRemoveLineItem, handleRemoveSingleItem, instance, form, errors, formType }) => {
    const { updateLoadedData } = useContext(DataContext);

    const [ formErrors, setFormErrors ] = useState({})

    const [ numLineItems, setNumLineItems ] = useState([0])
    const onAddLineItem = () => {
        const lastKey = numLineItems[numLineItems.length - 1];
        setNumLineItems(numLineItems => ([...numLineItems, lastKey + 1]));

        //add line item to form object
        handleAddLineItem();
    }

    const onRemoveLineItem = () => {
        var lineItemsArray = numLineItems;
        if (lineItemsArray.length > 1){
            lineItemsArray.pop()
            setNumLineItems(lineItemsArray);

            //remove line item to form object
            handleRemoveLineItem();
        }
        else {
            console.log('Requires at least 1 lineitem')
        }
    }

    const onRemoveItem = (instance) => {
        var lineItemsArray = numLineItems;
        if (lineItemsArray.length > 1){
            lineItemsArray.pop()
            setNumLineItems(lineItemsArray);

            //remove line item to form object
            handleRemoveSingleItem(instance);
        }
        else {
            console.log('Requires at least 1 lineitem')
        }
    }

    const handleFieldUpdate = (e) => {
        onFieldUpdate(e);
    }

    useEffect(() => {
        updateLoadedData(false);
    },[])

    useEffect(() => {
        if (formType === "update") 
        {
            var lineItemsArray = [0];

            for (var i = 1; i < form.lineitems.length; i ++)
            {
                lineItemsArray.push(i)
            }
            setNumLineItems(lineItemsArray)
        }
    },[form])

    useEffect(()=> {
        if (errors)
        {
                setFormErrors(errors);
        }
    },[errors])

    return (
        <form className="form-instance" id="po-form">
            <h2 id="poform-heading">
                PO Form - &nbsp;
                <input type="text" 
                    id="poform-pto"
                    className="poform-xlarge" 
                    onChange={ handleFieldUpdate }
                    data-key="title"
                    data-instance={ instance }
                    value={form.title} />
            </h2>
            <div className="login-error" id="error-privilige">{ formErrors.privilige }</div>

            <label htmlFor="date" id="label-date" className="poform-label">Date</label>
            <p id="poform-date"
                className="poform-large" 
                data-key="date"
                data-instance={ instance } >
                {form.date}
            </p>
            
            <label htmlFor="ponum" id="label-ponum" className="poform-label">PO#</label>
            <p id="poform-ponum"
                className="poform-large" 
                placeholder="PO Number"
                onChange={ handleFieldUpdate }
                data-key="ponum"
                data-instance={ instance }>
                {(form.ponum + form.polet).toString()}
            </p>
            <label htmlFor="ponum" id="label-gl" className="poform-label">GL#</label>
            <p id="poform-gl"
               className="poform-large" 
               data-key="gl"
               data-instance={ instance }>
                   {form.gl}
            </p>
            <h2 id="poform-payee">Payee</h2>
            <span className="general-error" id="error-gl">{ formErrors.gl }</span>

            <label htmlFor="pto" id="label-pto" className="poform-label">To</label>
            <input type="text" 
                    id="poform-pto"
                    className="poform-xlarge" 
                    onChange={ handleFieldUpdate }
                    data-key="pto"
                    data-instance={ instance }
                    value={form.pto} />
            <div className="general-error" id="error-pto">{ formErrors.pto }</div>
            <label htmlFor="paddress" id="label-paddress" className="poform-label">Address</label>
            <input type="text" 
                    id="poform-paddress"
                    className="poform-xlarge" 
                    onChange={ handleFieldUpdate }
                    data-key="paddress"
                    data-instance={ instance }
                    value={form.paddress} />
            <label htmlFor="pcity" id="label-pcity" className="poform-label">City, Province</label>
            <input type="text" 
                    id="poform-pcity"
                    className="poform-xlarge" 
                    onChange={ handleFieldUpdate }
                    data-key="pcity"
                    data-instance={ instance }
                    value={form.pcity} />
            <label htmlFor="ppostal" id="label-ppostal" className="poform-label">Postal Code</label>
            <input type="text" 
                    id="poform-ppostal"
                    className="poform-xlarge" 
                    onChange={ handleFieldUpdate }
                    data-key="ppostal"
                    data-instance={ instance }
                    value={form.ppostal} />
            <h2 id="poform-supplier">Supplier</h2>
            <input type="text"
                    id="poform-sto" 
                    className="poform-xlarge" 
                    onChange={ handleFieldUpdate }
                    data-key="sto"
                    data-instance={ instance }
                    value={form.sto} />
            <div className="general-error" id="error-sto">{ formErrors.sto }</div>
            <input type="text" 
                    id="poform-saddress"
                    className="poform-xlarge" 
                    onChange={ handleFieldUpdate }
                    data-key="saddress"
                    data-instance={ instance }
                    value={form.saddress} />
            <input type="text" 
                    id="poform-scity"
                    className="poform-xlarge" 
                    onChange={ handleFieldUpdate }
                    data-key="scity"
                    data-instance={ instance }
                    value={form.scity} />
            <input type="text" 
                    id="poform-spostal"
                    className="poform-xlarge" 
                    onChange={ handleFieldUpdate }
                    data-key="spostal"
                    data-instance={ instance }
                    value={form.spostal} />
            <br />
            <table id="poform-lineitems" className="poform-table">
                <thead>
                    <tr>
                        <td>Quantity</td>
                        <td>Description</td>
                        <td>Unit</td>
                        <td>Cost</td>
                    </tr>
                </thead>
                <tbody>
                    { numLineItems.map((numInst)=> (
                        <POLineItem handleFieldUpdate={handleFieldUpdate}
                                    handleRemoveItem={ onRemoveItem }
                                    key={ numInst } instance={ instance } 
                                    lineInstance={ numInst } form={form} 
                        />
                    ))}
                    <tr>
                        <td></td>
                        <td>
                            <span className="general-error" id="error-subtotal">{ formErrors.subtotal }</span>
                        </td>
                        <td>
                            <label htmlFor="subtotal" id="label-subtotal">Sub-Total</label>
                        </td>
                        <td>
                            <p id="poform-subtotal"
                               className="poform-readonly" 
                               data-key="subtotal"
                               data-instance={ instance }>
                               {form.subtotal} 
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <span className="general-error" id="error-hst">{ formErrors.hst }</span>
                        </td>
                        <td>
                            <label htmlFor="hst" id="label-hst" >HST</label>
                        </td>
                        <td>
                            <input type="text" 
                                    id="poform-hst"
                                    className="poform-xsmall" 
                                    onChange={ handleFieldUpdate }
                                    data-key="hst"
                                    data-instance={ instance }
                                    value={form.hst} />
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <span className="general-error" id="error-total">{ formErrors.total }</span>
                        </td>
                        <td>
                            <label htmlFor="total" id="label-total">Total</label>
                        </td>
                        <td>
                            <input type="text" 
                                    id="poform-total"
                                    className="poform-xsmall" 
                                    onChange={ handleFieldUpdate }
                                    data-key="total"
                                    data-instance={ instance }
                                    value={form.total} />
                        </td>
                    </tr>
                </tbody>
            </table>
            <a href="#" onClick={ onAddLineItem } 
                id="poform-additem" className="general-button">Add Item
            </a>
            <a href="#" onClick={ onRemoveLineItem } 
                id="poform-removeitem" className="general-button width-button">Remove Item
            </a>
        </form>
    )
}

export default POForm;