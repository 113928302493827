import React from 'react';

const POReview = ({ togglePopUp, handleApprovePO, form }) => {        
    const handleToggle = () => {
    }

    const handleApprove = () => {
        handleApprovePO("approved", true)
        togglePopUp();
    }

    const handleReject = () => {
        handleApprovePO("approved", false)
        togglePopUp();
    }
    const onFieldUpdate = (e) => {
        handleApprovePO(e.target.getAttribute('data-key'), e.target.value)
    }

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="modal-close" onClick={handleToggle}>
                    &times;
                </span>
                <h3>Review PO:</h3>
                    <input type="text" 
                        className="poform-large" 
                        placeholder="GL Number"
                        onChange={ onFieldUpdate }
                        data-key="gl"
                        data-instance={ 0 }
                        value={form.gl} />
                    <br />
                    <input type="button" 
                        onClick={ handleApprove } 
                        value="Approve" 
                        className="general-button form-updatebutton"/>
                    <input type="button" 
                        onClick={ handleReject } 
                        value="Reject" 
                        className="general-button form-deletebutton"/>
            </div>
        </div>
    )
}

export default POReview;