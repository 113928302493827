import React from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { DataContext } from '../contexts/DataContext.js';

const Footer = () => {
    // Data loaded
    const { updateLoadedData } = useContext(DataContext);

    const onRedirectDataLoad = () => {
        updateLoadedData(false);
    }
    
    return (
        <footer className="footer">
            <Link to="/" onClick={ onRedirectDataLoad } className="general-link"><h2>BRWS</h2></Link>
            <div className="footer-linkbar">
                <Link to="login" onClick={ onRedirectDataLoad } className="general-link">Log In</Link> 
                <Link to="members" onClick={ onRedirectDataLoad } className="general-link">Members</Link>
                <Link to="member-create" onClick={ onRedirectDataLoad } className="general-link">Create Member</Link>               
                <Link to="court-infractions" onClick={ onRedirectDataLoad } className="general-link">Infractions</Link>
                <Link to="infraction-create" onClick={ onRedirectDataLoad } className="general-link">Create Infractions</Link>
                <Link to="purchase-orders" onClick={ onRedirectDataLoad } className="general-link">Purchase Orders</Link>
                <Link to="po-create" onClick={ onRedirectDataLoad } className="general-link">Create PO</Link>
                <Link to="programs" onClick={ onRedirectDataLoad } className="general-link">Programs</Link>
                <Link to="programs-create" onClick={ onRedirectDataLoad } className="general-link">Create Program</Link>
            </div>
        </footer>
    );
}

export default Footer;