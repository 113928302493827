import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ProgramForm from '../Programs/ProgramForm.js';
import AlertDialog from '../functional/AlertDialogMUI.js';
import { DataContext } from '../contexts/DataContext.js';
import { UserContext } from '../contexts/UserContext.js';
import { handleItemDelete, 
         handleCookieValidation,
         handleFieldUpdate,
         updatePOState
        } from '../functional/helperFunctions.js';

const ProgramUpdateForm = ({ form }) => {
    // Data loaded
    const { loadedData, updateLoadedData } = useContext(DataContext);
    // User Context
    const { userPrefs } = useContext(UserContext);

    // Updates the k,v pair of a form field for a given object
    const onFieldUpdate = (e) => {
        handleFieldUpdate(e, updateState);
    }

    const updateState = (key, value, lineInstance) => {
        updatePOState(key, value, lineInstance, currentItem, setCurrentItem);
    }

    const onUpdateDelete = async () => {
        const redirectPage = await handleItemDelete(currentItem._id, setErrors, '/api/programs/');
        console.log(redirectPage)
        if (redirectPage === 0)
        {
            routeChange();
        }
        else if (redirectPage === 1)
        {
            routeLoginChange();
        }
        else if (redirectPage === 2)
        {
            console.log('Privilige Error')
        }
        else
        {
            console.log('Unknown Update Delete Error')
        }
    }

    useEffect(() => {
        handleCookieValidation(routeLoginChange);
    },[])

    const navigate = useNavigate();
    const routeChange = () => {
        navigate('/programs');
    }
    const routeLoginChange = () => {
        navigate('/login');
    }

    const [ errors, setErrors ] = useState({})
    const fetchPUTInfraction = async (routeChange) =>{
        var errorFlag = false;
        var tempObject = currentItem;

        const data = await fetch(process.env.REACT_APP_API + '/api/programs/' + currentItem._id, {
            credentials: 'include',
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(tempObject),
        })

        const json = await data.json();

        if (json.errors)
        {
            errorFlag = true;
            setErrors({});

            if (json.errors.jwt)
            {
                routeLoginChange();
            }
            else if (json.errors.privilige)
            {
                setErrors({ "privilige": json.errors.privilige })
            }

        }

        if (!errorFlag)
        {
            routeChange();
        }
    }

    const onUpdateSubmit = () => {
        fetchPUTInfraction(routeChange);
    }

    // Sets basic placeholder object before data loads from backend
    const [ currentItem, setCurrentItem ] = useState({})

    // When the form loads in from parent component, sets state
    useEffect(() => {
        setCurrentItem({
            _id: form._id,
            title: form.title,
            author: form.author,
            createdAt: form.createdAt,
            date: form.date ? form.date : "",
            description: form.description ? form.description : "",
            participants: form.participants ? form.participants : "",
            daysToRegister: form.daysToRegister ? form.daysToRegister : "",
            recipientEmail : form.recipientEmail ? form.recipientEmail : ""
        })

        updateLoadedData(true)
    }, [form])

    if (loadedData)
    {
        return (
            <>
                <div className="form-container" id="form-update">
                    <ProgramForm onFieldUpdate={onFieldUpdate}
                            key={ 0 } instance={ 0 } errors={errors} 
                            form={currentItem} formType={'update'} />
                </div>
                <div className="form-instance" id="po-updatesubmit">
                    <input type="button" 
                        onClick={ onUpdateSubmit } 
                        value="Update" 
                        className="general-button form-updatebutton"/>
                    <AlertDialog buttonName="Delete" 
                            buttonColour="error"
                            handleClickAction={onUpdateDelete}
                            mainText={"If you do not wish to delete the form, click Cancel."}
                            subBtn1 = "Delete"
                            subBtn2 = "Submit and add new..." />
                </div>   
            </>
        )
    }
    else
    {
        return (
            <div>
                Loading...
            </div>
        )
    }
    

}

export default ProgramUpdateForm;