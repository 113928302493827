import React from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext.js';

const AccountBox = ({onLogout}) => {
    // User Context
    const { userPrefs } = useContext(UserContext);

    // Logging out
    const handleLogout = () => {
        onLogout();
    }

    if (userPrefs.isLoggedOn)
    {
        return (
            <div className="header-accounts">
                <span className="general-greeting">Hi {userPrefs.name} at Staff Level {userPrefs.privilige}</span>
                <button onClick={ handleLogout } className="general-button header-accountbutton header-linktobutton">Log Out</button>
            </div>
        )
    }
    else
    {
        return (
            <div className="header-accounts">
                <Link to="login" className="general-link">Log In</Link>
                <Link to="signup" className="general-button header-accountbutton header-buttontolink">Sign Up</Link>
            </div>
        )
    }

    
}

export default AccountBox;