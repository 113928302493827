import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MemberForm from '../Members/MemberForm.js';
import AlertDialog from '../functional/AlertDialogMUI.js';
import { handleCookieValidation, handleFieldUpdate }  from '../functional/helperFunctions.js';

const CreateMember = ({formType, newMember}) => {
    const heading = "Create a Member Record";

    // Creates form objects and handles amount of forms allowed at once
    const [ forms, setForms ] = useState({
        name: ""
        });

    const navigate = useNavigate();
    const routeChange = () => {
        navigate('/members');
    }
    const routeLoginChange = () => {
        navigate('/login');
    }
    const routeAddAnotherChange = () => {
        navigate('/member-create');
        window.location.reload();
    }

    // Updates the k,v pair of a form field for a given object
    const onFieldUpdate = (e) => {
        handleFieldUpdate(e, updateState)
    }

    const updateState = (key, value) => {
        setForms({...forms, [key]: value })
    }

    const updateNewMember = () => {
        newMember();
    }

    const [ errors, setErrors ] = useState({})
    const fetchPOSTMember = async (addAnother) => {
        var errorFlag = false;

        const data = await fetch(process.env.REACT_APP_API + '/api/members', {
            credentials: 'include',
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(forms),
        })

        const json = await data.json();

        if (json.errors)
        {
            errorFlag = true;

            var errorObject = errors;

            errorObject = {};

            for (const [k, v] of Object.entries(json.errors)) {
                errorObject[k] = v;
            }

            setErrors(errorObject);    
        }

        if (!errorFlag)
        {
            if (formType == "cv")
            {
                updateNewMember();
            }
            else if (addAnother)
            {
                routeAddAnotherChange();
            }
            else
            {
                console.log('not like this!!!')
                routeChange();
            }
        }
    }
    
    const onFormSubmit = (addAnother) => {
        console.log('creating...')
        fetchPOSTMember(addAnother);
    }

    useEffect(() => {
        handleCookieValidation(routeLoginChange);
    },[])

    return (
        <div className="grid-container" id="create-form">
            <div className="general-header" id="home-top">
                <h3 className="home-top">{heading}</h3>
            </div>
            <div className="form-container">
                <MemberForm handleFieldUpdate={onFieldUpdate} key={ 0 } instance={ 0 } form={forms} errors={errors} />
            </div>
            <div className="form-instance" id="infraction-submit">
                <AlertDialog buttonName="submit" 
                            buttonColour="info"
                            handleClickAction={onFormSubmit}
                            mainText={"If you do not wish to submit the form(s), click Cancel."}
                            subBtn1 = "Submit"
                            subBtn2 = "Submit and add new..."  />
            </div>
        </div>
    )
}

export default CreateMember;